.parent-containers {
  padding-left: 15px !important;
  padding-top: 15px !important;
}

.radio-container {
  padding-left: 25px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.input-span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.radio-label {
  font-size: 20px !important;
}

.form-control-label {
  font-size: 18px !important;
  font-weight: 550 !important;
}

.MuiFormControl-root {
  display: block;
}

.label-note {
  color: red;
  font-style: italic;
  padding-left: 6px;
  font-size: 17px;
}
