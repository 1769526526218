.parent-container {
  border-radius: 10px;
  width: 100%;
  padding: 0px !important;
}

.header {
  font-size: 25px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  color: #5EC5BA
}

.sub-header {
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  font-weight: 600;
}

.content-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.day-text {
  font-size: 15px;
  font-weight: 600;
  width: 100px !important;
}

.row-slot-container {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.textfield-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  width: 232px
}

.time-picker-success > div{
  border: 1px solid lightgray;
  border-radius: 6px;
  background: transparent !important;
}

.time-picker-error > div{
  border: 1px solid red;
  border-radius: 6px;
  background: transparent !important;
}


.react-time-picker__inputGroup__input{
  min-width: 18px !important;
}
