.screening-parent-container {
  border-radius: 10px;
  width: 100%;
  padding: 0px !important;
}

.screening-header {
  font-size: 25px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  color: #5ec5ba;
}

.screening-sub-header {
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  font-weight: 600;
}

.screening-content-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
}

.que-checkbox-container {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  align-items: center;
}
.mandatory-note {
  color: red;
  font-size: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  font-weight: 500;
}
